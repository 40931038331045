<script setup lang="ts">
   import {
      Dialog,
      DialogPanel,
      TransitionChild,
      TransitionRoot,
   } from '@headlessui/vue';
   import { XMarkIcon } from '@heroicons/vue/24/outline';
   const openSideBar = defineModel<boolean>('openSideBar', {
      required: true,
   });
   const { locale } = useI18n();
   const isRtl = computed(() => locale.value === 'ar');

   const emit = defineEmits<{
      (event: 'close'): void;
   }>();
</script>

<template>
   <TransitionRoot as="div" :show="openSideBar">
      <Dialog class="relative z-50 sm:hidden">
         <!-- Transparent Background Dialog  -->
         <TransitionChild
            as="template"
            enter="transition-opacity ease-linear duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
         >
            <div class="fixed inset-0 0 bg-primary-900/50" />
         </TransitionChild>
         <div
            class="fixed inset-0 flex bg-primary-900/10"
            :class="isRtl ? 'rtl' : 'ltr'"
         >
            <TransitionChild
               as="template"
               enter="transition ease-in-out duration-300 transform"
               :enter-from="isRtl ? 'translate-x-full' : '-translate-x-full'"
               enter-to="translate-x-0"
               leave-from="translate-x-0"
               :leave-to="isRtl ? 'translate-x-full' : '-translate-x-full'"
            >
               <DialogPanel
                  class="relative flex flex-col w-full max-w-full sm:max-w-xs bg-white"
                  :class="{ 'ml-auto': isRtl, 'mr-auto': !isRtl }"
               >
                  <div class="flex justify-end px-4 mt-6">
                     <XMarkIcon
                        @click.prevent="openSideBar = false"
                        class="text-black cursor-pointer w-7 h-7"
                     />
                  </div>
                  <slot />
               </DialogPanel>
            </TransitionChild>
         </div>
      </Dialog>
   </TransitionRoot>
</template>
